body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.acuminFont{
    font-family: acumin-pro;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@layer base {
    @font-face {
        font-family: "AgencyBold";
        src: url("./fonts/Agdasima/Agdasima-Bold.ttf");
        font-display: swap;
    }
    html {
      font-family: "AgencyBold", system-ui, sans-serif;
  }
    @font-face {
        font-family: "Agency";
        src: url("./fonts/Agdasima/Agdasima-Regular.ttf");
        font-display: swap;
    }
  
    @font-face {
        font-family: "Bentonsans";
        src: url("./fonts/Source-Sans-3/SourceSans3-Regular.ttf");
        font-display: swap;
    }
    @font-face {
        font-family: "BentonsansBold";
        src: url("./fonts/Source-Sans-3/SourceSans3-Bold.ttf");
        font-display: swap;
    }
    @font-face {
        font-family: "BentonsansLight";
        src: url("./fonts/Source-Sans-3/SourceSans3-Light.ttf");
        font-display: swap;
    }
    
  
  }