#root {
  min-height: 100vh;
}
html,
body,
#root {
  min-height: 100% !important;
  height: 100%;
}

body{
  padding: 0!important;
}

.rdrMonthAndYearWrapperCustom{
  visibility: hidden;
}

.rdrDateRangeWrapperCustom{
  width: 350px;
}

@font-face {
  font-family:"acumin-pro";
  src:url("https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
  }